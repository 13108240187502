* {
  box-sizing: border-box;
}

body {
  font-family: 'Open Sans', sans-serif;
  margin: 0;
  background-color: #f8f8f8;
  min-height: 100vh;
}

.menu-section {
  position: relative;
  padding:  20px;
  text-align: center;
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 20px;
}
.menu-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.menu-title {
  font-size: 2.5em;
  font-family: 'Oswald', sans-serif;
  color: #333;
}

.sort-items {
  flex: 1;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.sort-items select {
  padding: 8px 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1em;
  background-color: #fff;
  color: #333;
  cursor: pointer;
  transition: border-color 0.3s;
}

.sort-items select:hover {
  border-color: #ff6600;
}

.search-wrapper {
    flex: 1;
    text-align: right;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.search-icon {
    cursor: pointer;
    font-size: 1.5em;
    color: #333;
}

.search-icon:hover {
    color: #ff4d4d;
}

.search-container {
    display: flex;
    align-items: center;
    margin-left: 10px;
}

.search-input {
    padding: 8px 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1em;
    width: 200px;
    transition: width 0.3s ease-in-out;
}

.cancel-search {
    background: none;
    border: none;
    font-size: 1.5em;
    cursor: pointer;
    color: #ff4d4d;
    margin-left: 10px;
}

.menu-categories {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 15px;
  margin-bottom: 40px;
}

.category-button {
  background-color: #333;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.category-button:hover {
  background-color: #555;
}

.category-button.selected {
  background-color: #ff6600;
  color: #fff;
}

.menu-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 30px;
}

.menu-card {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  width: 300px;
  height: 450px; /* Adjust height as needed */
  border-radius: 15px;
  overflow: hidden;
  position: relative;
  background: rgba(255, 255, 255, 0.1);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  transition: transform 0.3s, box-shadow 0.3s;
  margin: 20px;
}

.menu-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  scale: 1.02;
  transition: transform 0.1s ease, box-shadow 0.1s ease, scale 0.1s ease;
}

.menu-image {
  height: 200px; /* Fixed height */
  width: 100%;
  object-fit: cover; /* Ensures the image covers the area */
  position: relative;
}

.price-menu {
  padding: 5px 10px;
  font-size: 1.0em;
  color: #fff;
  position: absolute;
  top: 10px; /* Position at the top */
  right: 10px; /* Position at the right */
  background-color: rgba(255, 129, 19, 0.6);
  border-radius: 5px;
  font-weight: bold;
  z-index: 2;
}

.item-info-menu {
  text-align: center;
  padding: 5px;
  word-wrap: break-word;
  height: auto;
  overflow: hidden;
  margin-top: 60px;
  position: relative;
  bottom: 40px;
  align-items: center;
  text-align: center;
}

.item-info h3 {
  font-size: 1.4em;
  margin-bottom: 5px;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

.description-menu {
  font-size: 0.9em;
  color: #666;
  border-radius: 5px;
  height: 200px;
  width: 100%;
  padding: 10px;

}

.category-menu {
  font-size: 0.9em;
  color: #fff;
  background-color: rgba(255, 129, 19, 0.6);
  padding: 5px 10px;
  border-radius: 5px;
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 2;
}

.details-menu {
  font-size: 0.8em;
  color: #333;
  padding: 10px;
  border-radius: 5px;
  background-color: rgba(236, 4, 4, 0.7);
  backdrop-filter: blur(5px);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  position: absolute;
  bottom:-15px; /* Adjust to reduce white space */
  left: 50%;
  transform: translateX(-50%);
}

.fa-star {
  margin-right: 5px;
}

.fas {
  color: #ffcc00;
}

.far {
  color: #ddd;
}

.buy-button-menu {
  background-color: #333;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  margin-bottom: 20px;
  width: 80%;
  margin-left: 10%;
}

.buy-button-menu:hover {
  background-color: #ff6600;
  color: #fff;
}

.menu-more-button {
  background-color: #333;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  margin-top: 20px;
}

.menu-more-button:hover {
  background-color: #ff6600;
  color: #fff;
}

