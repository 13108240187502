/* Modal styles */
.modal-dialog {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.8); /* Black background with opacity */
  }
  
  .modal-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    position: relative;
    width: 80%;
    max-width: 500px;
  }
  
  .modal-content h2 {
    margin-bottom: 20px;
  }
  
  .modal-button {
    background-color: #333;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 20px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
    margin: 10px;
  }
  
  .modal-button:hover {
    background-color: #ff6600;
    color: #fff;
  }
  
  .modal-button i {
    margin-right: 10px;
  }
  
  .model-close {
    position: absolute;
    top: 10px;
    right: 20px;
    color: #333;
    font-size: 24px;
    font-weight: bold;
    cursor: pointer;
  }
  
  .model-close:hover {
    color: #ff6600;
  }
  