* {
  box-sizing: border-box;
}

body {
  font-family: 'Open Sans', sans-serif;
  margin: 0;
  background-color: #f8f8f8;
  min-height: 100vh;
}

.texmex-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.texmex {
  position: relative;
  padding: 20px 20px;
  text-align: center;
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 20px;
}
.texmex-title {
  font-size: 2.5em;
  justify-content: center; /* Center the title horizontally */
  align-items: center;
  font-family: 'Oswald', sans-serif;
}

.sort-items {
  flex: 1;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.sort-items select {
  padding: 8px 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1em;
  background-color: #fff;
  color: #333;
  cursor: pointer;
  transition: border-color 0.3s;
}

.sort-items select:hover {
  border-color: #ff6600;
}

.search-wrapper {
    flex: 1;
    text-align: right;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.search-icon {
    cursor: pointer;
    font-size: 1.5em;
    color: #333;
}

.search-icon:hover {
    color: #ff4d4d;
}

.search-container {
    display: flex;
    align-items: center;
    margin-left: 10px;
}

.search-input {
    padding: 8px 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1em;
    width: 200px;
    transition: width 0.3s ease-in-out;
}

.cancel-search {
    background: none;
    border: none;
    font-size: 1.5em;
    cursor: pointer;
    color: #ff4d4d;
    margin-left: 10px;
}
.slick-next, .slick-prev {
  font-size: 2em;
  color: #333;
  z-index: 1;
}

.slick-next:hover, .slick-prev:hover {
  color: #ff6600;
}

.texmex-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 30px;
}

.texmex-card {
  display: flex !important;
  flex-direction: row;
  width: 100%;
  max-width: 900px; /* Increased width */
  border-radius: 15px;
  overflow: hidden;
  background: rgba(255, 255, 255, 0.1);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  transition: transform 0.3s, box-shadow 0.3s;
  margin: 20px auto;
}

.texmex-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.texmex-image {
  flex: 1; /* Decreased flex value to make the image smaller */
  height: auto;
  overflow: hidden;
}

.texmex-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.texmex-content {
  flex: 2;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  position: relative; /* Added for positioning the button */
}

.texmex-content h3 {
  font-size: 1.5em;
  margin-bottom: 10px;
  color: #333;
}

.texmex-content p {
  font-size: 1em;
  color: #666;
  margin-bottom: 60px;
}

.texmex-price {
  font-size: 1.2em;
  color: #333;
  margin-bottom: 10px;
}

.texmex-buy-button {
  background-color: #333;
  color: #fff;
  border: none;
  padding: 10px 20px; /* Increased size */
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  position: absolute;
  bottom: 20px;
  right: 20px; /* Positioned to the right bottom corner */
}

.texmex-buy-button:hover {
  background-color: #ff6600;
  color: #fff;
}

.texmex-more-button {
  background-color: #333;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  margin-top: 40px;
}

.show-more-button:hover {
  background-color: #ff6600;
  color: #fff;
}

.texmex-card-small {
  display: flex;
  flex-direction: column;
  height: 400px; /* Smaller card height */
  width: 300px; /* Smaller card width */
  border-radius: 15px;
  overflow: hidden;
  background: rgba(255, 255, 255, 0.1);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  transition: transform 0.3s, box-shadow 0.3s;
  margin: 20px;
}

.texmex-card-small:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.texmex-image-small {
  height: 200px; /* Smaller image height */
  overflow: hidden;
}

.texmex-image-small img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.texmex-content-small {
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; /* Center align content */
  text-align: center; /* Center align text */
}

.texmex-content-small h3 {
  font-size: 1.2em;
  margin-bottom: 5px;
  color: #333;
}

.texmex-content-small p {
  font-size: 0.9em;
  color: #666;
  margin-bottom: 5px;
}

.texmex-price-small {
  font-size: 1em;
  color: #333;
  margin-bottom: 5px;
  position: absolute;
  top: 20px;
  right: 20px;
  background-color: #ff6600;
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
}
.texmex-buy-button-small {
  background-color: #333;
  color: #fff;
  border: none;
  padding: 10px 20px; /* Increased size */
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  position: absolute;
  bottom: 20px;
  right: 20px; /* Positioned to the right bottom corner */
}

.texmex-buy-button-small:hover {
  background-color: #ff6600;
  color: #fff;
}


/* Media Queries for Mobile Responsiveness */
@media (max-width: 768px) {
  .texmex-card {
    flex-direction: column; /* Stack image and content vertically */
  }

  .texmex-image {
    height: 200px; /* Adjust height for smaller screens */
  }

  .texmex-content {
    align-items: center; /* Center align content */
    text-align: center; /* Center align text */
    position: static; /* Reset position for mobile */
  }

  .texmex-buy-button {
    position: static; /* Reset position for mobile */
    transform: translateX(0); /* Reset transform for mobile */
    margin-top: 10px; /* Add margin for spacing */
  }
}
