body {
    font-family: 'Open Sans', sans-serif;
    margin: 0;
    background-color: #f8f8f8;
    min-height: 100vh;
}

.admin-dashboard {
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
    position: relative; /* Ensure relative positioning for absolute elements */
    background: rgba(255, 255, 255, 0.1);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(10px);
    border-radius: 15px;
}

.top-buttons {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    flex-wrap: wrap;
}

.choose-file-button,
.logout-button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 16px;
    border-radius: 5px;
    margin-bottom: 10px;
}

.choose-file-button:hover,
.logout-button:hover {
    background-color: #0056b3;
}

h1 {
    text-align: center;
    margin-bottom: 20px;
    font-size: 2em;
    color: #333;
}

.form {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 20px;
}

.form input,
.form select,
.form button {
    padding: 10px;
    font-size: 14px;
    border: 1px solid #ddd;
    border-radius: 5px;
}

.form button {
    background-color: #007bff;
    color: white;
    border: none;
    cursor: pointer;
}

.form button:hover {
    background-color: #0056b3;
}

.crop-container {
    position: relative;
    width: 100%;
    height: 400px; /* Adjust height as needed */
    background-color: #333;
    display: none; /* Hide crop container by default */
}

.crop-container.active {
    display: block; /* Show crop container when active */
}

.crop-button {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #ff4d4d;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 16px;
    border-radius: 5px;
}

.crop-button:hover {
    background-color: #ff1a1a;
}

.preview-container {
    text-align: center;
    margin-top: 20px;
}

.preview-image {
    max-width: 100%;
    height: auto;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.admin-menu-list {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
}

.admin-menu-item {
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    overflow: hidden;
    width: calc(25% - 20px); /* Adjust width to show 4 items per row */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s, box-shadow 0.3s;
    position: relative;
}

.admin-menu-item:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.admin-menu-item img {
    width: 100%;
    height: 150px; /* Adjust height for image */
    object-fit: cover;
}

.admin-item-info {
    padding: 10px;
    background: rgba(255, 255, 255, 0.9); /* Ensure text background is opaque */
    box-sizing: border-box;
}

.admin-item-info h3 {
    margin: 0 0 5px;
    font-size: 1.2em;
    color: #333;
}

.admin-item-info p {
    margin: 5px 0;
    color: #666;
}

.admin-item-info .edit-button {
    margin-right: 10px;
    padding: 5px 10px;
    font-size: 12px;
    cursor: pointer;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
}

.admin-item-info .edit-button:hover {
    background-color: #0056b3;
}

.admin-item-info .delete-button {
    padding: 5px 10px;
    font-size: 12px;
    cursor: pointer;
    background-color: #ff4d4d;
    color: white;
    border: none;
    border-radius: 4px;
}

.admin-item-info .delete-button:hover {
    background-color: #ff1a1a;
}

.admin-item-price {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 1em;
    font-weight: bold;
}

.admin-item-category {
    position: absolute;
    top: 10px;
    left: 10px;
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 1em;
    font-weight: bold;
}

/* Media Queries for Mobile */
@media (max-width: 1024px) {
    .admin-menu-item {
        width: calc(33.333% - 20px); /* Show 3 items per row */
    }
}

@media (max-width: 768px) {
    .top-buttons {
        flex-direction: column;
        align-items: center;
    }

    .choose-file-button,
    .logout-button {
        width: 100%;
        text-align: center;
    }

    .admin-menu-item {
        width: calc(50% - 10px); /* Show 2 items per row */
    }
}

@media (max-width: 480px) {
    .admin-menu-item {
        width: 100%; /* Show 1 item per row */
    }
}