.hero {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.4)), url('/images/photo.jpg') no-repeat top center/cover;
  position: relative;
  overflow: hidden;
}

.hero-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 1200px;
  color: #fff;
  padding: 20px;
}

.hero-left {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  text-align: center;
}

.hero-left h1 {
  font-size: 4rem;
  font-family: 'Arial', sans-serif;
  color: #fff;
  font-weight: bold;
  margin-bottom: 5px;
}

.hero-left h2 {
  font-size: 5rem;
  font-family: 'Oswald', sans-serif;
  color: #fff;
  font-weight: bold;
  margin-top: 5px;
}

.hero-left p {
  font-size: 1.3rem;
  font-family: 'Arial', sans-serif;
  font-weight: bold;
}

.cta-button {
  padding: 10px 15px;
  font-size: 1rem;
  background: #ff4d4d;
  color: white;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.cta-button:hover {
  transform: translateY(-3px);
  box-shadow: 0 5px 15px rgba(255, 77, 77, 0.3);
}

.hero-right {
  flex: 1.6;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

.hero-image {
  width: 100%;
  height: 250px;
  object-fit: cover;
  border-radius: 25px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.5);
  transition: transform 0.3s ease;
}

.hero-item {
  position: relative;
}

.hero-item:hover .hero-image {
  transform: scale(1.1);
}

.hero-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  padding: 10px;
  text-align: center;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.hero-item:hover .hero-overlay {
  opacity: 1;
  transform: scale(1.1);
}

.hero-overlay h2 {
  margin: 0;
  font-size: 1.2em;
}

.hero-overlay p {
  margin: 5px 0 0;
  font-size: 1em;
}

.hero-scroll-indicator {
  position: absolute;
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  color: #fff;
  animation: bounce 2s infinite;
}

.hero-scroll-indicator span {
  display: block;
  margin-bottom: 10px;
}

@keyframes bounce {
  0%, 20%, 50%, 70%, 100% {
      transform: translateY(0);
  }
  20% {
      transform: translateY(-30px);
  }
  60% {
      transform: translateY(-15px);
  }
}

/* Media Queries for Mobile Responsiveness */
@media (max-width: 768px) {
  .hero {
    height: auto;
    padding: 20px;
  }

  .hero-content {
    flex-direction: column;
    text-align: center;
  }

  .hero-left h1 {
    font-size: 2.5rem;
  }

  .hero-left h2 {
    font-size: 3rem;
  }

  .hero-left p {
    font-size: 1rem;
  }

  .image-grid {
    grid-template-columns: 1fr;
    gap: 10px;
  }

  .hero-image {
    height: 200px;
  }

  .hero-scroll-indicator {
    bottom: 20px;
    left: 50%;
  }
}