.header {
    background-color: rgba(51, 51, 51, 0.4);
    color: #fff;
    padding: 15px 20px;
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 1000;
    transition: background-color 0.3s, color 0.3s;
    backdrop-filter: blur(2px);
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
}

.header .container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
}

.header.scrolled,
.header.scrolled .search-results {
    background-color: rgba(255, 255, 255, 1);
    color: #000;
}


.header.scrolled .header-nav ul li a,
.header.scrolled .search-container,
.header.scrolled .admin-icon,
.header.scrolled .lang-toggle {
    color: #000;
}

.logo {
    display: flex;
    align-items: center;
}

.logo h1 {
    margin: 0;
    font-size: 2em;
    color: inherit;
    font-family: 'Oswald', sans-serif;
    font-weight: 600;
    margin-left: 10px;
}

.logo-icon {
    width: 30px;
    height: 30px;
}

.header-nav {
    margin-right: 20px;
    cursor: pointer;
    display: flex;
    align-items: center;
    flex-grow: 1;
    justify-content: center;
}

.header-nav ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
}

.header-nav ul li {
    margin-left: 30px;
}

.header-nav ul li a {
    font-weight: bold;
    color: inherit;
    text-decoration: none;
    font-size: 0.9em;
    transition: color 0.3s;
}

.header-nav ul li a:hover {
    color: #ff4d4d;
}

.header-actions {
    display: flex;
    align-items: center;
}

.lang-toggle {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 1em;
    color: inherit;
    margin-left: 10px;
    transition: color 0.3s;
}

.lang-toggle:hover {
    color: #ff4d4d;
}

.menu-toggle {
    display: none;
    flex-direction: column;
    cursor: pointer;
    transition: transform 0.3s ease;
}

.menu-toggle .bar {
    width: 20px;
    height: 2px;
    background-color: #fff;
    margin: 3px 0;
    transition: 0.3s;
}

.menu-toggle:hover {
    transform: rotate(90deg);
}

/* Media Query for Small Screens */
@media (max-width: 768px) {
    .header-nav {
        position: absolute;
        top: 70px;
        left: 0;
        width: 100%;
        background-color: #333;
        flex-direction: column;
        align-items: center;
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.3s;
    }

    .header-nav.open {
        max-height: 300px;
    }

    .header-nav ul {
        flex-direction: column;
    }

    .header-nav ul li {
        margin: 10px 0;
    }

    .menu-toggle {
        display: flex;
    }
    .header.scrolled .menu-toggle .bar {
        background-color: #ffffff;
    }
    .header.scrolled .header-nav
    {
        background-color: rgba(255, 255, 255, 1);
        color: #000;
    }
}