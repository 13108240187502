.footer {
    background-color: #333;
    color: #fff;
    text-align: center;
    width: 100%; /* Ensure the footer spans the full width */
}

.footer-content {
    padding: 20px 60px; /* Adjust padding to ensure consistent color */
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin-bottom: 20px;
}

.contact-info, .opening-hours, .social-icons {
    flex: 1;
    min-width: 250px;
    margin: 10px;
}

.contact-info h3, .opening-hours h3, .social-icons h3 {
    font-size: 1.5em;
    margin-bottom: 10px;
    color: #ff6600;
}

.contact-info p, .opening-hours p {
    font-size: 1em;
    margin: 5px 0;
}

.contact-info a {
    color: #fff;
    text-decoration: none;
}

.contact-info a:hover {
    text-decoration: underline;
}

.social-icons a {
    color: #fff;
    font-size: 1.5em;
    margin: 0 10px;
    transition: color 0.3s;
}

.social-icons a:hover {
    color: #ff6600;
}

.footer-bottom {
    width: 100%;
    padding: 20px 60px; /* Adjust padding to ensure consistent color */
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 0.9em;
    color: #ffffff;
    background-color: #444; /* Lighter background color */
}

.footer-bottom-left {
    display: flex;
    align-items: center;
}

.footer-icon {
    width: 20px;
    height: 20px;
    margin-right: 10px;
}

.payment-systems {
    display: flex;
    align-items: center;
}

.payment-icons {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.payment-icon {
    display: flex;
    align-items: center;
   
    font-size: 1.1em;
}

.payment-icon-img {
    width: 30px;
    height: auto;
    margin-right: 5px;
}

.payment-icon span {
    margin: 5px;
    font-size: 0.9em;
    color: #fff;
}