.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
}

.login-form {
    background: white;
    padding: 40px; /* Increased padding for larger box */
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    width: 400px; /* Increased width for larger box */
}

.login-form h2 {
    margin-bottom: 20px;
}

.login-form input {
    display: block;
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.login-buttons {
    display: flex;
    justify-content: space-between;
}

.login-buttons button {
    padding: 10px 20px;
    background-color: #333;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    flex: 1;
    margin: 0 5px;
}

.login-buttons button:hover {
    background-color: #575757;
}