html, body {
    margin: 0;
    padding: 0;
    overflow-x: hidden; /* Hide horizontal scroll bar */
    width: 100%;
}

.admin-icon {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background: none;
    border: none;
    cursor: pointer;
    font-size: 1.5em;
    color: #333;
    transition: color 0.3s;
    z-index: 1001; /* Ensure the admin icon is on top */
}

.admin-icon:hover {
    color: #ff4d4d;
}