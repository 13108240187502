/* Popup styles */
.popup-tab {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.8); /* Black background with opacity */
  }
  
  .popup-content {
    background-color: transparent; /* Remove background color */
    padding: 0; /* Remove padding */
    border-radius: 10px;
    text-align: center;
    position: absolute;
    width: 80%;
    max-width: 800px;
    font-family: 'Open Sans', sans-serif; /* Explicitly set font family */
  }
  
  .popup-image {
    width: 100%;
    height: 100%;
    position: relative; /* Position relative for overlay elements */
    object-fit: cover; /* Ensure the image covers the entire area */
  }

  .popup-image img {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    object-fit: cover; /* Ensure the image covers the entire area */
  }
  .popup-title {
    font-size: 1.2em;
    color: #fff;
    position: relative;
    bottom: 60px; /* Position above the buy button */
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.7); /* Add text shadow for better readability */
  }
  
  .popup-title h3 {
    font-size: 1.5em;
    margin: 0;
  }
  
  .popup-price {
    font-size: 1.2em;
    color: #fff;
    background-color: rgba(255, 129, 19, 0.6);
    padding: 10px 15px;
    border-radius: 10px;
    position: absolute;
    top: 20px;
    left: 20px;
    z-index: 1000;
  }
  
  .popup-buy-button {
    background-color: #333;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 20px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
    position: absolute;
    bottom: 10px; /* Position at the bottom */
    right: 30px; /* Position at the right */
    z-index: 2;
  }
  
  .popup-buy-button:hover {
    background-color: #ff6600;
    color: #fff;
  }
  
  .popup-close {
    position: absolute;
    top: 20px;
    right: 20px;
    color: #333;
    background: #ffffff;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 35px;
    font-weight: bold;
    cursor: pointer;
  }
  
  .popup-close:hover {
    color: #ff6600;
  }
/* Media Queries for Mobile Responsiveness */
@media (max-width: 768px) {   


    .popup-title {
      font-size: 1.2em;
      color: #fff;
      position: relative;
      bottom: 70px; /* Position above the buy button */
      z-index: 200;
      text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.7); /* Add text shadow for better readability */
    }
    
    .popup-title h3 {
      font-size: 1em;
      margin: 30px;
     
  
    }
    .popup-buy-button {
      background-color: #333;
      color: #fff;
      border: none;
      padding: 10px 20px;
      border-radius: 20px;
      cursor: pointer;
      transition: background-color 0.3s, color 0.3s;
      position: absolute;
      bottom: 2px; /* Position at the bottom */
      right: 10px; /* Position at the right */
      font-size: 0.6em;
    }
    
    .popup-buy-button:hover {
      background-color: #ff6600;
      color: #fff;
    }
    .popup-price {
      font-size: 1em;
      color: #fff;
      background-color: rgba(255, 129, 19, 0.6);
      padding: 10px 15px;
      border-radius: 10px;
      position: absolute;
      top: 10px;
      left: 10px;
      z-index: 1000;
    }
    .popup-close {
      position: absolute;
      top: 10px;
      right: 10px;
      color: #333;
      background: #ffffff;
      border-radius: 50%;
      width: 30px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 30px;
      font-weight: bold;
      cursor: pointer;
    }
  }  